.div_campus {
    padding: 8% 4% 4% 4%;
    height: 100vh;
}

.div_campus>h1 {
    text-align: center;
}

.container-campus {
    display:block;
    justify-content:center;
    position: relative;
    margin: 15px 10%;
    gap: 30px;
    height: 450px;
    overflow: hidden;
    border-color: var(--title-color-tertiary);
    border-style: solid;
}

.container-logo-campus {
    display: flex;
    flex-direction: column;
    justify-items: center;
    background-image: url(../../../../assets/images/UcatecVblanco.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    position: absolute;
    height: 450px;
}

.container-logo-campus>img {
    width:fit-content;
    height: 500px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.container-RRSS-campus {
    display: none;
}

.container-campus:hover .container-RRSS-campus{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    width: 100%;
    position: absolute;
    height: 450px;
    gap: 30px;
}

.container-RRSS-campus>a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 20px;
    margin-bottom: 5px;
}

.container-RRSS-campus>a>img {
    width: 80px;
    height: fit-content;

}

@media (max-width: 1100px) {
    .container-RRSS-campus{
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        width: 100%;
        position: absolute;
        height: 100px;
        bottom: 0;
        gap:30px;
    }
    .container-RRSS-campus>a>img {
        width: 40px;
    }
}

@media (max-width: 768px) {
    .div_campus {
        margin-left: 0px;
        margin-right: 0px;
        padding: 4%;
    }
    .container-campus {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        height: 350px;
    }
    .container-logo-campus {
        height: 300px;
    }
    .container-RRSS-campus{
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        width: 100%;
        position: absolute;
        height: 100px;
        bottom: 0;
        gap:30px;
    }
    .container-RRSS-campus>a>img {
        width: 40px;
    }
}