.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #FFBC00;
    background-color: rgba(0,0,0,.4);
    backdrop-filter: blur(50px);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .navbar::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rebeccapurple;
  }
  .logoHeader {
    width: 60px;
    justify-self: center;
    align-self: center;
}

  .navbar-brand {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-right: 4%;
    padding-left: 4%;

  }
  
  .navbar-menu a {
    text-decoration: none;
    color: #FFBC00;
    font-size: 20px;
    font-family: "Coiny";
  }
  
  .menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    border-color: transparent;
    background-color: transparent;
  }
  
  .menu-icon span {
    width: 25px;
    height: 3px;
    background-color: #FFBC00;
    margin: 4px 0;
    transition: all 0.3s ease-in-out;
  }
  
  .navbar-menu {
    list-style: none;
    display: flex;
    font-family: "Coiny";
    color: black;
    gap: 30px;
    padding-right: 4%;
  }

  .navbar-menu>ul {
    margin-right: 20px;
  }

  .navbar-menu>li {
    padding-left: 0px;
  }
  
  .navbar-menu.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 85%;
    left: 0;
    padding: 20px;
    width: 100%;
    text-align: center;
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(80px);
  }
  


  .active {
    background-color: aquamarine;
  }

  .navbar-menu a:hover {
    background: linear-gradient(to right, #00D8D7,#496bca);
    background-clip: text;
    color: transparent;
  }
  nav ul li a:not(.active):hover{
    background-color: cadetblue;
  }

  @media (max-width: 990px) {
    .navbar-menu {
      display: none;
    }
  
    .menu-icon {
      display: flex;
    }
    .navbar-menu a {
      font-size: 24px;
    }
  }