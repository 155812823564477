/* Sección Cronograma */

.div_schedule {
    padding: 8% 4% 4% 4%;
}

.div_schedule>h1 {
    text-align: center;

}

.tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    margin-bottom: 3%;
}

.tab>p {
    padding: 10px 20px;
    background: radial-gradient(var(--background-color-secondary), var(--background-color-primary)) padding-box,
        linear-gradient(to right, var(--title-color-secondary), var(--title-color-primary)) border-box;
    border-radius: 60px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.3s;
}

.tab>p:hover {
    background: linear-gradient(to right, var(--title-color-secondary), var(--title-color-primary)) padding-box,
        linear-gradient(to right, var(--title-color-secondary), var(--title-color-primary)) border-box;
}

.tab>p.active {
    background: linear-gradient(to right, var(--title-color-secondary), var(--title-color-primary)) padding-box,
        linear-gradient(to right, var(--title-color-secondary), var(--title-color-primary)) border-box;
}

.tabcontent {
    margin: 15px 10%;
    display: none;
}

.tabcontent.active {
    display: block;
}

.schedule-item {
    display: flex;
    flex-direction: column;
}

.schedule-item-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 217px;
    border-bottom: 2px solid transparent;
    background: linear-gradient(#32256E, #32256E) padding-box,
        linear-gradient(to right, var(--title-color-primary), var(--title-color-secondary)) border-box;
    cursor:pointer;
}

.schedule-item-1>p {
    align-self: center;
    font-size: 25px;
    min-width: 220px;
}

.item-image {
    height: 120px;
    border-radius: 50%;
    border: 2px solid transparent;
    background: linear-gradient(#32256E, #32256E) padding-box,
        linear-gradient(to right, var(--title-color-primary), var(--title-color-secondary)) border-box;
    margin-right: 40px;
}

.item-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-right: 40px;
}

.item-data>p {
    text-align: left;
    margin: 0px;
}

.item-data.Apertura {
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    padding: 5px 15px;
    background: linear-gradient(to right, #03D6D7, var(--title-color-quaternary));
    border-radius: 30px;
    text-align: center;
}

.item-data.Charla {
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    padding: 5px 15px;
    background: linear-gradient(to right, var(--title-color-secondary), var(--title-color-primary));
    border-radius: 30px;
    text-align: center;
}

.item-data.Taller {
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    padding: 5px 15px;
    background: linear-gradient(to right, #03D6D7, var(--title-color-quaternary));
    border-radius: 30px;
    text-align: center;
}

.item-data.Break {
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    padding: 5px 15px;
    background: linear-gradient(to right, #03D6D7, var(--title-color-quaternary));
    border-radius: 30px;
    text-align: center;
}

.item-data.Sponsor {
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    padding: 5px 15px;
    background: linear-gradient(to right, #03D6D7, var(--title-color-quaternary));
    border-radius: 30px;
    text-align: center;
}

.item-data.Conclusión {
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    padding: 5px 15px;
    background: linear-gradient(to right, #03D6D7, var(--title-color-quaternary));
    border-radius: 30px;
    text-align: center;
}

.item-data-title {
    font-size: 25px;
    font-weight: 500;
}

.item-data-name {
    font-size: 18px;
    color: #00CEFF;
}

.schedule-item-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    background: #21184e;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease-out;
}

.schedule-item-2>p {
    font-size: 18px;
    margin: 40px 119px;
    text-align: left;
    width: 100%;
    margin-left: 220px;
}

.schedule-item-2.expanded {
    max-height: 500px;
}

.icon-arrow{
    height: 20px;
    margin-right: 40px;
    margin-left: auto;
    transition: transform 0.3s ease-out;
}

.icon-arrow.rotated {
    transform: rotate(180deg);
}

@media (max-width: 992px) {
    .tabcontent {
        margin-left: 0;
        margin-right: 0;
    }

    .schedule-item-1 {
        height: fit-content;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .schedule-item-1>p {
        font-size: 20px;
        min-width: 180px;
    }

    .item-data-title {
        font-size: 20px;
    }

    .schedule-item-2>p {
        margin-left: 180px;
    }
}

@media (max-width: 768px) {
    .div_schedule {
        margin-left: 0px;
        margin-right: 0px;
        padding: 4%;
    }

    .tabcontent {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }

    .schedule-item-1 {
        min-height: 100px;
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-areas: 
        "time data icon"
        "photo data icon";
        grid-template-rows: 30px auto;
        grid-template-columns: 110px 1fr 20px;
        justify-content: left;
        padding: 20px;
        
    }

    .schedule-item-1>p {
        font-size: 14px;
        width: fit-content;
        grid-area: time;
        min-width: 0px;
        margin-top: 0px;
        align-self: last baseline;
    }

    .item-image {
        width: 80px;
        height: 80px;
        grid-area: photo;
        margin-right: 0px;
        align-self: baseline;
    }

    .item-data {
        gap: 5px;
        grid-area: data;
        margin-right: 0px;
        margin-right: 15px;
    }

    .item-data-title {
        font-size: 16px;
        font-weight: 500;
    }

    .item-data-name {
        font-size: 14px;
    }

    .icon-arrow {
        grid-area: icon;
        justify-self:flex-end;
        margin-right: 0px;
        margin-left: auto;
        
    }

    .schedule-item-2>p {
        font-size: 14px;
        margin: 20px;
    }

    #type {
        font-size: 12px;
        font-weight: 400;
        padding: 2px 10px;
    }
}